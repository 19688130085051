import { Link, useLocation } from 'react-router-dom';
import { HiOutlineDotsVertical, HiOutlineX } from 'react-icons/hi';
import { useState } from 'react';

const Header = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const location = useLocation();
  const pathname = location.pathname.slice(1);

  return (
    <header className="Header">
      <div className="Header_body">
        <button className="menuBtn" onClick={() => setToggleMenu(!toggleMenu)}>
          {!toggleMenu ? <HiOutlineDotsVertical /> : <HiOutlineX />}
        </button>
        <div className="Header_title">{pathname || 'dashboard'}</div>
      </div>
      <nav className={toggleMenu ? 'menu open' : 'menu'}>
        <div className="menu_item">
          <Link to="/" onClick={() => setToggleMenu(!toggleMenu)}>
            Dashboard
          </Link>
        </div>
        <div className="menu_item">
          <Link to="/bombole" onClick={() => setToggleMenu(!toggleMenu)}>
            Bombole
          </Link>
        </div>
        <div className="menu_item">
          <Link to="/tolle" onClick={() => setToggleMenu(!toggleMenu)}>
            Tolle
          </Link>
        </div>
        <div className="menu_item">
          <Link to="/commesse" onClick={() => setToggleMenu(!toggleMenu)}>
            Commesse
          </Link>
        </div>
        <div className="menu_item">
          <Link to="/todo" onClick={() => setToggleMenu(!toggleMenu)}>
            Todo
          </Link>
        </div>
      </nav>
    </header>
  );
};

export default Header;

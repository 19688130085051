import { v4 as uuidv4 } from 'uuid';

const categories = ['Bombole', 'Tolle', 'Comesse', 'Todos'].map((category) => {
  return {
    id: uuidv4(),
    name: category,
    list: [],
  };
});
const LocalStorage = {
  initializeData() {
    if (!localStorage.getItem('FISvernice')) {
      return localStorage.setItem('FISvernice', JSON.stringify(categories));
    }
  },
  getData() {
    return JSON.parse(localStorage.getItem('FISvernice'));
  },
  updateData(newData) {
    localStorage.setItem('FISvernice', JSON.stringify(newData));
  },
};
export default LocalStorage;

import { Link } from 'react-router-dom';

const Dashboard = (props) => {
  const { commesse, scarcity } = props;
  // console.log(props);
  return (
    <section className="Dashboard">
      <Link to="/todo">
        <fieldset className="todo">
          <legend>Compiti</legend>
        </fieldset>
      </Link>
      <Link to="/commesse">
        <fieldset className="commesse">
          <legend>Commesse</legend>
          <div>Commesse attive</div>
          <div>{commesse?.length}</div>
          <div>Scarcità RAL bombole</div>
          <div>{scarcity?.length}</div>
        </fieldset>
      </Link>

      <section className="stock">
        <Link to="/bombole">
          <fieldset className="bombole">
            <legend>Bombole</legend>
          </fieldset>
        </Link>
        <Link to="/tolle">
          <fieldset className="tolle">
            <legend>Tolle</legend>
          </fieldset>
        </Link>
      </section>
    </section>
  );
};

export default Dashboard;

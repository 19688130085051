import { useState } from 'react';

import RalItem from '../UI/RalItem';
import AddRalItem from '../UI/AddRalItem';
import Scarcity from '../Scarcity/Scarcity';

const Bombole = (props) => {
  const { bombole, setBombole, updateCategories, scarcity } = props;
  const [newBombolaPopup, setNewBombolaPopup] = useState(false);
  // const [RALscarciry, setRALscarcity] = useState([]);
  // console.log(bombole);

  // useEffect(() => {
  //   if (bombole && bombole.list) {
  //     const scarceItems = bombole.list.filter((item) => item.qty <= 2);
  //     setRALscarcity(scarceItems);
  //   }
  // }, [bombole]);

  const newRALPopup = () => {
    setNewBombolaPopup(!newBombolaPopup);
  };

  const saveNewBombola = (newRal) => {
    // console.log(newRal);
    if (newRal.ral) {
      const updateBombole = {
        ...bombole,
        list: [...(bombole.list || []), newRal],
      };
      setBombole(updateBombole);
      setNewBombolaPopup(!newBombolaPopup);
      updateCategories(updateBombole);
    }
    setNewBombolaPopup(!newBombolaPopup);
  };

  const deleteBombola = (bombola) => {
    console.log(bombola);
    const updatedList = bombole.list.filter((item) => item.id !== bombola.id);
    const updateBombole = { ...bombole, list: updatedList };
    setBombole(updateBombole);
    updateCategories(updateBombole);
  };

  return (
    <>
      <section className="Bombole">
        <button className="Bombole_add" onClick={newRALPopup}>
          {newBombolaPopup ? 'Close' : 'Add New'}
        </button>
        {newBombolaPopup && <AddRalItem saveNewBombola={saveNewBombola} />}
        <Scarcity scarcity={scarcity} />

        {bombole &&
          bombole.list &&
          bombole.list.map((bombola) => {
            return (
              <RalItem
                key={bombola.id}
                bombola={bombola}
                setBombole={setBombole}
                btn={deleteBombola}
              />
            );
          })}
      </section>
    </>
  );
};

export default Bombole;

const Scarcity = ({ scarcity }) => {
  return (
    <>
      {scarcity.length > 0 && (
        <fieldset className="Scarcity">
          <legend>Scarcità</legend>
          <ul>
            {scarcity.map((ral, index) => (
              <li key={index}>RAL {ral}</li>
            ))}
          </ul>
        </fieldset>
      )}
    </>
  );
};

export default Scarcity;

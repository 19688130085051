import { useState } from 'react';
import Commessa from '../UI/Commessa';
import AddCommessa from '../UI/AddCommessa';
import Scarcity from '../Scarcity/Scarcity';

const Comesse = (props) => {
  // console.log(props);
  const {
    bombole,
    commesse,
    setCommesse,
    updateCategories,
    scarcity,
    setScarcity,
  } = props;
  const [newCommessaPopup, setNewCommessaPopup] = useState(false);

  // console.log(commesse);

  const newCommessa = () => {
    setNewCommessaPopup(!newCommessaPopup);
  };

  const saveNewCommessa = (data) => {
    console.log(data);
    if (data.comm && data.ralFisse && data.ralMobili) {
      const updateCommesse = {
        ...commesse,
        list: [...(commesse.list || []), data],
      };
      setCommesse(updateCommesse);
      setNewCommessaPopup(!newCommessaPopup);
      updateCategories(updateCommesse);
    }
    setNewCommessaPopup(!newCommessaPopup);
  };

  const deleteCommessa = (data) => {
    console.log(data);
    const updatedList = commesse.list.filter((item) => item.id !== data.id);
    const updateCommesse = { ...commesse, list: updatedList };
    setCommesse(updateCommesse);
    updateCategories(updateCommesse);
  };

  return (
    <section className="Commesse">
      <button className="Bombole_add" onClick={newCommessa}>
        {newCommessaPopup ? 'Close' : 'Add New'}
      </button>
      {newCommessaPopup && <AddCommessa saveNewCommessa={saveNewCommessa} />}
      <Scarcity scarcity={scarcity} />
      {commesse &&
        commesse.list &&
        commesse.list.map((commessa) => {
          return (
            <Commessa
              scarcity={scarcity}
              setScarcity={setScarcity}
              key={commessa.id}
              bomboleList={bombole.list}
              commessa={commessa}
              btn={deleteCommessa}
            />
          );
        })}
    </section>
  );
};

export default Comesse;

import { v4 as uuidv4 } from 'uuid';
import { useState } from 'react';
import {
  HiOutlineClipboardCopy,
  HiClipboardCopy,
  HiOutlineCheck,
} from 'react-icons/hi';

const AddRalItem = (props) => {
  const { saveNewBombola } = props;
  const [addRal, setAddRal] = useState({
    ral: '',
    qty: 0,
  });

  const handleInputChange = (e) => {
    setAddRal(() => ({
      ...addRal,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newRAL = { ...addRal, id: uuidv4() };
    saveNewBombola(newRAL);
  };

  return (
    <fieldset className="RalItem">
      <legend>Add New Bombola</legend>
      <input
        name="ral"
        type="text"
        className="RalItem_ral"
        placeholder="RAL"
        onChange={handleInputChange}
      ></input>
      <input
        name="qty"
        type="number"
        className="RalItem_qty"
        placeholder="q-tà"
        onChange={handleInputChange}
      ></input>
      <button onClick={handleSubmit}>
        <HiOutlineClipboardCopy />
      </button>
    </fieldset>
  );
};

export default AddRalItem;

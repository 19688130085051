import { v4 as uuidv4 } from 'uuid';
import { useState } from 'react';
import {
  HiOutlineClipboardCopy,
  HiClipboardCopy,
  HiOutlineCheck,
} from 'react-icons/hi';

const AddCommessa = (props) => {
  const { saveNewCommessa } = props;
  const [addCommessa, setAddCommessa] = useState({
    comm: '',
    ralFisse: '',
    ralMobili: '',
  });

  const handleInputChange = (e) => {
    setAddCommessa(() => ({
      ...addCommessa,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newCommessa = { ...addCommessa, id: uuidv4() };
    saveNewCommessa(newCommessa);
  };

  return (
    <fieldset className="Commessa">
      <legend className="Commessa_legend">Nuova Commessa</legend>
      <div className="Commessa_body">
        <div className="Commessa_fields">
          <input
            className="Commessa_input"
            type="number"
            name="comm"
            placeholder="numero di commessa"
            onChange={handleInputChange}
          />
          <input
            className="Commessa_input"
            type="number"
            name="ralFisse"
            placeholder="RAL parti fisse"
            onChange={handleInputChange}
          />
          <input
            className="Commessa_input"
            type="number"
            name="ralMobili"
            placeholder="RAL parti mobili"
            onChange={handleInputChange}
          />
        </div>
        <button className="Commessa_btn" onClick={handleSubmit}>
          <HiOutlineClipboardCopy />
        </button>
      </div>
    </fieldset>
  );
};

export default AddCommessa;

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useEffect, useState } from 'react';

import Dashboard from './components/Dashboard/Dashboard';
import Bombole from './components/Bombole/Bombole';
import Tolle from './components/Tolle/Tolle';
import Comesse from './components/Comesse/Comesse';
import Todo from './components/Todo/Todo';
import Layout from './components/Layout/Layout';
import LocalStorage from './utils/LocalStorage';
import './App.css';

function App() {
  const [categories, setCategories] = useState([]);
  const [bombole, setBombole] = useState({});
  const [tolle, setTolle] = useState({});
  const [commesse, setCommesse] = useState({});
  const [todos, setTodos] = useState({});
  const [scarcity, setScarcity] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      LocalStorage.initializeData();
      const currentCategories = LocalStorage.getData() || [];
      setCategories(currentCategories);

      const bomboleCategory =
        currentCategories.find((category) => category.name === 'Bombole') || {};
      setBombole(bomboleCategory);

      const tolleCategory =
        currentCategories.find((category) => category.name === 'Tolle') || {};
      setTolle(tolleCategory);

      const commesseCategory =
        currentCategories.find((category) => category.name === 'Comesse') || {};
      setCommesse(commesseCategory);

      const todosCategory =
        currentCategories.find((category) => category.name === 'Todos') || {};
      setTodos(todosCategory);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const checkScarcity = () => {
      const commesseList = commesse.list || [];
      const bomboleList = bombole.list || [];

      const ralMap = new Map();
      bomboleList.forEach((item) => ralMap.set(item.ral, item.qty));

      const newScarcity = new Set();

      commesseList.forEach((commessa) => {
        const allRals = [...commessa.ralFisse, ...commessa.ralMobili];
        allRals.forEach((ral) => {
          const qty = ralMap.get(ral) || 0;
          if (qty < 2) {
            newScarcity.add(commessa.ralFisse);
            newScarcity.add(commessa.ralMobili);
          }
        });
      });

      setScarcity(Array.from(newScarcity).flat());
    };

    checkScarcity();
  }, [commesse, bombole]);
  // console.log(scarcity);

  const updateCategories = (update) => {
    const updatedCategories = categories.map((category) =>
      category.name === update.name ? update : category
    );
    setCategories(updatedCategories);
    LocalStorage.updateData(updatedCategories);
  };

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route
              index
              element={
                <Dashboard commesse={commesse.list} scarcity={scarcity} />
              }
            />
            <Route path="/todo" element={<Todo />} />
            <Route
              path="/bombole"
              element={
                <Bombole
                  scarcity={scarcity}
                  bombole={bombole}
                  setBombole={setBombole}
                  updateCategories={updateCategories}
                />
              }
            />
            <Route
              path="/tolle"
              element={
                <Tolle
                  tolle={tolle}
                  setTolle={setTolle}
                  updateCategories={updateCategories}
                />
              }
            />
            <Route
              path="/commesse"
              element={
                <Comesse
                  scarcity={scarcity}
                  setScarcity={setScarcity}
                  bombole={bombole}
                  commesse={commesse}
                  setCommesse={setCommesse}
                  updateCategories={updateCategories}
                />
              }
            />
          </Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;

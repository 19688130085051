import { useState } from 'react';

import RalItem from '../UI/RalItem';
import AddRalItem from '../UI/AddRalItem';

const Tolle = (props) => {
  const { tolle, setTolle, updateCategories } = props;
  const [newTollaPopup, setNewTollaPopup] = useState(false);

  const newRALPopup = () => {
    setNewTollaPopup(!newTollaPopup);
  };

  const saveNewTolla = (newRal) => {
    console.log(newRal);
    const updateBombole = {
      ...tolle,
      list: [...(tolle.list || []), newRal],
    };
    setTolle(updateBombole);
    setNewTollaPopup(!newTollaPopup);
    updateCategories(updateBombole);
  };

  const deleteTolla = (tolla) => {
    console.log(tolla);
    const updatedList = tolle.list.filter((item) => item.id !== tolla.id);
    const updateTolle = { ...tolle, list: updatedList };
    setTolle(updateTolle);
    updateCategories(updateTolle);
  };

  return (
    <section className="Bombole">
      <button className="Bombole_add" onClick={newRALPopup}>
        {newTollaPopup ? 'Close' : 'Add New'}
      </button>
      {newTollaPopup && <AddRalItem saveNewBombola={saveNewTolla} />}
      {tolle &&
        tolle.list &&
        tolle.list.map((bombola) => {
          return (
            <RalItem
              key={bombola.id}
              bombola={bombola}
              deleteBombola={deleteTolla}
            />
          );
        })}
    </section>
  );
};

export default Tolle;

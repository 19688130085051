import { HiOutlineTrash } from 'react-icons/hi';

const RalItem = (props) => {
  const { bombola, setBombole, btn } = props;
  console.log(props);

  return (
    <fieldset className="RalItem">
      <legend>RAL</legend>
      <div className="RalItem_ral">{bombola.ral}</div>
      <div className="RalItem_qty">{bombola.qty} pz.</div>
      {btn && (
        <button className="RalItem_btn" onClick={() => btn(bombola)}>
          <HiOutlineTrash />
        </button>
      )}
    </fieldset>
  );
};

export default RalItem;

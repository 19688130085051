const Commessa = (props) => {
  const { commessa, btn } = props;
  // console.log(props);

  return (
    <fieldset className="Commessa">
      <legend className="Commessa_legend">Commessa {commessa.comm}</legend>
      <ul className="Commessa_fields">
        <li>
          <span>RAL Parti Fisse</span>
          <span>{commessa.ralFisse}</span>
        </li>
        <li>
          <span>RAL Parti Mobili</span>
          <span>{commessa.ralMobili}</span>
        </li>
      </ul>
      <button className="Commessa_delBtn" onClick={() => btn(commessa)}>
        Delete
      </button>
    </fieldset>
  );
};

export default Commessa;
